<template>
  <div class="diagrambox">
    <div class="diagrambox-topbox">
      <el-button type="primary" icon="el-icon-arrow-left" @click="goback()">返回</el-button>

      <p>告警时间:</p>
      <el-date-picker v-model="Orderobj.startTime" type="date" value-format="yyyy-MM-dd"
        placeholder="选择日期" @change="settime($event)">
      </el-date-picker>
      <p>告警类型:</p>
      <el-select v-model="Orderobj.status" placeholder="请选择" :popper-append-to-body="false"
        @change="shutDown($event)" clearable>
        <el-option v-for="item in statuslist" :key="item.value" :label="item.value"
          :value="item.value">
        </el-option>
      </el-select>
      <p>处理结果:</p>
      <el-select v-model="Orderobj.result" placeholder="请选择" :popper-append-to-body="false"
        clearable @change="Results($event)">
        <el-option v-for="item in warninglist" :key="item.value" :label="item.value"
          :value="item.value">
        </el-option>
      </el-select>
      <p>告警区域:</p>
      <!-- <el-input v-model="Orderobj.radarCoordinates" placeholder="请输入区域" clearable></el-input> -->
      <el-select v-model="Orderobj.radarCoordinates" placeholder="请选择"
        :popper-append-to-body="false" clearable>
        <el-option v-for="item in namelist" :key="item.value" :label="item.value"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="pagelist()">搜索</el-button>
      <el-button type="primary" icon="el-icon-refresh" @click="resetbtn()">重置</el-button>
      <!-- <el-button type="primary" @click="gotopolice()">设备报警信息</el-button> -->
    </div>
    <div class="diagrambox-center">
      <div class="leftbox">
        <div :class="{ action: isAction == text.id } " v-for="(text, i) in list" :key="i"
          @click="swArea(text)">
          <div class="maxbox">
            <p class="title">{{text.tylename}}</p>
            <div class="detailsbox">
              <div class="imgbox">
                <img :src="'http://183.94.86.130:6548/' + text.img" alt="">
              </div>
              <!-- <div class="imgbox" v-if="text.location === 1">
                <img :src="'http://183.94.86.130:8090/' + text.img" alt="">
              </div>
              <div class="imgbox" v-else>
                <img :src="'http://183.94.86.130:6548/' + text.img" />
              </div> -->
              <div class="textboxs">
                <p>类型:{{text.wfType}}</p>
                <p>时间:{{text.data}}</p>
                <p>经度:{{text.longitude}}</p>
                <p>纬度:{{text.dimension}}</p>
                <p>区域:{{text.radarCoordinates}}</p>
                <p>状态: <span v-if="text.status === '未处理'"
                    style="color:rgba(255, 246, 0, 1)">{{text.status}}</span>
                  <span v-else>{{text.status}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="paging">
          <el-pagination small background @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="Orderobj.pagSum" :page-sizes="[5]"
            :page-size="Orderobj.pageSize" layout="total, prev, pager, next" :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="centerbox">
        <TitleBox :titlename="'监控视频'" />
        <div class="videobox">

          <video id="videoElement" :src="'http://183.94.86.130:6548/' + videoSrc" controls autoplay
            muted></video>
          <!-- <video id="videoElement" v-if="locationshow === 1"
            :src="'http://183.94.86.130:8090/' + videoSrc" controls autoplay muted></video>
          <video id="videoElement" v-else :src="'http://183.94.86.130:6548/' + videoSrc" controls
            autoplay muted></video> -->

        </div>
        <div class="videofooter" ref="scrollDiv" @mousewheel="MouseWheel($event)">
          <div class="modebox" v-for="(item,index) in this.odraddobj.pictures" :key="index">
            <!-- <img :src="item.url" alt=""> -->
            <!-- {{index}} -->
            <el-image style="width: 100%; height: 100%" :src="item.url" :preview-src-list="srcList">
            </el-image>
          </div>
          <!-- <div class="modebox">
            <img :src="  this.odraddobj.pictures[1].url" alt="">
          </div>
          <div class="modebox">
            <img :src="  this.odraddobj.pictures[2].url" alt="">
          </div> -->
        </div>

      </div>
      <div class="rightbox">
        <TitleBox :titlename="'处理流程'" />
        <div class="btnbox">
          <el-button v-if="takabtnshow === 1" type="primary" size="small" @click="assign()">分派
          </el-button>
          <el-button v-if="takabtnshow === 1" type="primary" size="small" @click="falsePbtn()">误报
          </el-button>
        </div>
        <div class="center_box" v-if="takabtnshow === 3">
          <div class="top_box">
            <p>已分派处理人员: <img src="../../assets/homeimg/ren.png" alt="">
              <span>{{oaoderlist.principal}}</span>
            </p>
          </div>
          <div class="takebox">
            <div class="namebox">
              <p>备注: {{oaoderlist.workOrderDescription}}</p>
            </div>
          </div>
        </div>
        <div class="center_box" v-if="takabtnshow === 2">
          <div class="top_box">
            <p style="margin-top: 10px;">处理结果</p>
            <p class="mintext">操作员:{{oaoderlist.userName}} {{oaoderlist.finishDate}}</p>
            <!-- <p class="mintext">操作员:{{oaoderlist.userName}} {{oaoderlist.createTime}}</p> -->
            <p class="mintexttwo">结果:{{oaoderlist.status}}</p>
          </div>
          <div class="takebox">
            <div class="namebox">
              <p>结果说明:{{oaoderlist.abarbeitungContext}}</p>
            </div>
          </div>
          <div class="imgviderbox">
            <div class="imgbox" v-for="(item,index) in oaoderlist.rectificationPictures"
              :key="index">
              <el-image style="width: 100%; height: 100%" :src="item.url"
                :preview-src-list="ResultssrcList">
              </el-image>
            </div>
            <div class="imgbox" v-for="(item) in oaoderlist.rectificationVideos" :key="item.id">
              <video id="videoElement" :src="item.url" controls autoplay muted></video>
            </div>
          </div>

        </div>
        <div class="center_box" v-if="takabtnshow === 4">
          <div class="top_box">

            <p>错报误报</p>
            <p class="mintext">操作员:{{oaoderlist.principal}} {{oaoderlist.updataTime}}</p>
          </div>
        </div>

      </div>
    </div>
    <Details :class="detailsshow ? 'fadeIn' : ''" v-if="detailsshow" @cancel="cancel"
      @addbtn="addbtn" />
    <FalsePositives :class="falsePositivesshow ? 'fadeIn' : ''" v-if="falsePositivesshow"
      @falsePositivesbtn="falsePositivesbtn" @confirmdiobtn='confirmdiobtn' />
  </div>
</template>

<script>
import axios from 'axios'
import api from '../visualization/api'
import { mapGetters } from 'vuex'
import TitleBox from '../visualization/components/title/index.vue'
import Details from '../dialog/details.vue'
import FalsePositives from '../dialog/falsePositives.vue'

export default {
  props: {},
  components: {
    TitleBox,
    Details,
    FalsePositives
  },
  data () {
    return {
      total: 0, // 总条数
      isAction: 0,
      list: [],
      takabtnshow: 1,
      detailsshow: false,
      falsePositivesshow: false,
      Orderobj: {
        result: null, // 处理结果
        radarCoordinates: '', // 告警区域
        status: null, // 处理状态
        pagSum: 1, // 页码
        pageSize: 5, // 页大小
        startTime: '', // 开始时间
        endTime: '' // 结束时间
      },
      odraddobj: {
        // level: '1',
        longitude: '',
        latitude: '',
        riskDescribe: '',
        // riskDescribe: '描述信息',
        riskSite: '武大园',
        createTime: '',
        partnerType: '0',
        riskType: '0',
        // closeDate: '2022-08-10',
        userId: '1567030541022715905',
        userName: '汉南公安账号1',
        warnId: '',
        workTime: '', // 发生时间
        riskDeviceDescribe: '', // 拼接字段
        pictures: [
        ],
        videos: [
        ],
        videoUrl: ''
      },
      warninglist: [
        {
          value: '口头警告'
        },
        // {
        //   value: '行政拘留'
        // },
        {
          value: '行政处罚'
        },
        {
          value: '其他'
        }
      ],
      namelist: [
        {
          value: '牌洲'
        },
        {
          value: '潘湾'
        },
        {
          value: '老湾'
        },
        {
          value: '河道局'
        },
        {
          value: '螺山'
        }
      ],

      statuslist: [
        {
          value: '未处理'
        },
        {
          value: '已下发工单'
        },
        {
          value: '已处理'
        },
        {
          value: '误报'
        }
      ],
      odraddid: null,
      odrbmp: null,
      videoSrc: null,
      oaoderlist: null,
      locationshow: null,
      srcList: [],
      ResultssrcList: [],
      toDealWithimg: '', // 已处理工单图片
      beseurl: null,
      odrwarnId: null
    }
  },
  computed: {
    ...mapGetters('zsqy', ['pagedoobj'])

  },
  watch: {
    pagedoobj: {
      // deep: true,

      immediate: true,
      handler (val, old) {
        // console.log(val, '监听数据')
        this.pagedoobj = val
        // console.log(this.Orderobj, '监听obj')
      }
    }
  },
  methods: {
    MouseWheel (e) {
      const eventDelta = -e.wheelDelta || -e.deltaY * 40
      const scrollDiv = this.$refs.scrollDiv
      scrollDiv.scrollLeft = scrollDiv.scrollLeft + eventDelta / 2
    },
    shutDown (val) {
      if (val === '') {
        this.Orderobj.status = null
        this.Orderobj.result = null
      }
    },
    Results (val) {
      if (val === '') {
        this.Orderobj.status = null
        this.Orderobj.result = null
      }
    },
    settime (val) {
      // console.log(val)
      if (val != null) {
        this.Orderobj.startTime = val + ' 00:00:00'
        this.Orderobj.endTime = val + ' 23:59:59'
      } else {
        this.Orderobj.startTime = ''
        this.Orderobj.endTime = ''
      }
    },
    resetbtn () {
      this.Orderobj = {
        result: null, // 处理结果
        radarCoordinates: '', // 告警区域
        status: null, // 处理状态
        pagSum: 1, // 页码
        pageSize: 5, // 页大小
        startTime: '', // 开始时间
        endTime: '' // 结束时间
      }
      this.pageWarningWorkOrder()
    },
    pagelist () {
      this.Orderobj.pagSum = 1
      this.pageWarningWorkOrder()
    },
    falsePositivesbtn () {
      this.falsePositivesshow = false
    },
    confirmdiobtn () {
      this.updatStatus('误报', '误报')
    },
    falsePbtn () {
      this.falsePositivesshow = true
    },
    cancel () {
      this.detailsshow = false
    },
    addbtn (item) {
      var list = this.odraddobj.riskDeviceDescribe.split(' ')
      // console.log(list, item.riskDescribe, 'list')
      this.odraddobj.riskType = item.riskType
      this.odraddobj.partnerType = item.partnerType
      this.odraddobj.userId = item.userId
      this.odraddobj.userName = item.userName
      this.odraddobj.warnId = this.odrwarnId
      this.odraddobj.riskDescribe = item.riskDescribe
      if (item.riskDescribe === '') {
        if (this.odraddobj.riskType === 0) {
          this.odraddobj.riskDescribe = '疑似船只' + list[0]
          this.odraddobj.riskDeviceDescribe = '疑似船只' + list[0]
          // console.log(obj.alarmareaEnums, ' obj.alarmareaEnums')
        } else if (this.odraddobj.riskType === 1) {
          this.odraddobj.riskDescribe = '疑似车辆' + list[0]
          this.odraddobj.riskDeviceDescribe = '疑似车辆' + list[0]
        } else if (this.odraddobj.riskType === 2) {
          this.odraddobj.riskDescribe = '疑似人员' + list[0]
          this.odraddobj.riskDeviceDescribe = '疑似人员' + list[0]
        }
      }
      // console.log(this.odraddobj, 'this.odraddobj')

      api.deviceAdd(this.odraddobj).then(res => {
        if (res.status === 200) {
          this.updatStatus('已下发工单', '下发成功')
          // this.pageWarningWorkOrder()
          // this.pageswArea()
        }
      })
    },
    updatStatus (resulttext, success) {
      const obj = {
        alarmareaEnums: '',
        warningId: this.odraddid,
        workOrderStatus: resulttext,
        workOrderDescription: this.odraddobj.riskDescribe,
        principal: this.odraddobj.userName
      }
      // console.log(this.odraddobj.riskType, 'this.odraddobj.riskType')
      if (this.odraddobj.riskType === '0' || this.odraddobj.riskType === 0) {
        obj.alarmareaEnums = '疑似船只'
        // console.log(obj.alarmareaEnums, ' obj.alarmareaEnums')
      } else if (this.odraddobj.riskType === '1' || this.odraddobj.riskType === 1) {
        obj.alarmareaEnums = '疑似车辆'
      } else if (this.odraddobj.riskType === '2' || this.odraddobj.riskType === 2) {
        obj.alarmareaEnums = '疑似人员'
      }

      // axios.get( + '/zsqy/Radarworkorder/
      axios.post(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/zsqyUpateStauts', obj, { headers: { database: '11NatureReserves' } })
        .then((res) => {
          // console.log(res, '修改接口')
          if (res.data.status === 200) {
            this.$message.success(success)
            this.detailsshow = false
            this.falsePositivesshow = false
            // console.log('页面刷新前')
            this.pageWarningWorkOrder()
            this.pageswArea()
            // console.log('页面刷新后')
          } else {
            this.$message.warning(res.data.message)
          }
        })
    },
    assign () {
      this.detailsshow = true
    },
    gotopolice () {
      this.$router.push({ name: 'police' })
    },
    goback () {
      // this.$router.go(-1)
      this.$router.push({ name: 'warning' })
      this.$store.commit('zsqy/setfootershow', true)
    },
    swArea (item) {
      // console.log(item)
      this.isAction = item.id
      this.odrwarnId = item.warningId
      this.locationshow = item.location
      this.oaoderlist = item
      if (item.status === '未处理') {
        this.takabtnshow = 1
      } else if (item.status === '已处理') {
        this.takabtnshow = 2
        this.pagethirdInfo(item.oaticketId)
      } else if (item.status === '已下发工单') {
        this.takabtnshow = 3
      } else if (item.status === '误报') {
        this.takabtnshow = 4
      }
      this.odraddid = item.warningId
      this.odrbmp = item.bmp
      this.videoSrc = item.video
      // console.log(item)
      this.jiexi(item)
      // console.log(this.odraddobj)
    },
    jiexi (item) {
      // console.log(item)
      this.odraddobj.pictures = []
      this.odraddobj.videos = []
      this.ResultssrcList = []
      this.odraddobj.longitude = item.longitude
      this.odraddobj.latitude = item.dimension
      this.odraddobj.riskSite = item.radarCoordinates
      this.odraddobj.workTime = item.data
      // this.odraddobj.workTime = item.data + item.radarCoordinates + item.result
      // this.odraddobj.riskDeviceDescribe = item.data
      // this.beseurl = 'http://183.94.86.130:6548/' + this.base64ImgtoFile('data:image/png;base64,' + item.bmp)
      // console.log(item.bmp, 'qian')
      this.beseurl = null
      if (item.bmp) {
        this.beseurl = item.bmp
      }
      this.imgforEach(item.img, '图片')
      this.videoforEach(item.video, '视频')
    },
    imgforEach (img, name) {
      const list = img.split('#_#')
      // console.log(this.locationshow, '-=-=-=')
      if (this.beseurl !== null) {
        const radarobj = {
          name: '雷达图片',
          url: this.beseurl
        }
        this.odraddobj.pictures.push(radarobj)
      }
      list.forEach(ele => {
        const obj = {
          name: name,
          url: 'http://183.94.86.130:6548' + ele
        }
        this.odraddobj.pictures.push(obj)
      })
      this.odraddobj.pictures.forEach(ele => {
        this.srcList.push(ele.url)
      })
      // console.log(this.odraddobj.pictures, '-=-=')
      // this.ResultssrcList.push(this.odraddobj.pictures[1].url) // 处理结果数组
    },
    videoforEach (video, name) {
      const list = video.split('#_#')
      list.forEach(ele => {
        const obj = {
          name: name,
          url: 'http://183.94.86.130:6548' + ele
        }
        this.odraddobj.videos.push(obj)
        // if (this.locationshow === 1) {
        //   const obj = {
        //     name: name,
        //     url: 'http://183.94.86.130:8090' + ele
        //   }
        //   this.odraddobj.videos.push(obj)
        // } else {
        //   const obj = {
        //     name: name,
        //     url: 'http://183.94.86.130:6548' + ele
        //   }
        //   this.odraddobj.videos.push(obj)
        // }
      })
    },
    pagethirdInfo (id) {
      const obj = {
        id: id
      }
      api.pagethirdInfo(obj).then(res => {
        if (res.data) {
          this.oaoderlist = res.data
          this.oaoderlist.rectificationPictures.forEach(ele => {
            this.ResultssrcList.push(ele.url)
          })
        } else {
          this.$message.warning('该工单已为空')
        }
      })
    },
    // 分页
    handleSizeChange (val) {
      this.Orderobj.pageSize = val
      this.pageWarningWorkOrder()
    },
    // 切换分页
    handleCurrentChange (val) {
      this.Orderobj.pagSum = val
      this.pageWarningWorkOrder()
    },
    pageWarningWorkOrder () {
      axios.post(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/findPage', this.Orderobj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'res')
          this.list = res.data.data.records
          this.total = res.data.data.total

          // this.list.forEach(ele)
          // console.log(this.tableData, this.total, '-=-=')
        })
    },
    pageswArea () {
      axios.post(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/findPage', this.Orderobj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res, 'res')
          // this.list = res.data.data.recordss
          res.data.data.records.forEach(ele => {
            if (ele.id === this.isAction) {
              this.swArea(ele)
              // console.log('zhixing')
            }
          })
          // console.log(this.tableData, this.total, '-=-=')
        })
    }
  },
  created () {
    // console.log('...........')
    this.isAction = this.$route.params.id * 1
    // console.log(this.isAction, '-=')
    this.Orderobj.pagSum = this.$route.params.pagSum * 1

    this.Orderobj.result = this.pagedoobj.result // 处理结果
    this.Orderobj.radarCoordinates = this.pagedoobj.radarCoordinates // 告警区域
    this.Orderobj.status = this.pagedoobj.status // 处理状态
    // console.log(this.Orderobj, '初始化')
    this.pageWarningWorkOrder()
    this.pageswArea()
    // console.log(this.$route.params.id, 'ids')
    this.$store.commit('zsqy/setfootershow', false)
  },
  destroyed () {
  }

}
</script>
<style>
.el-table .warning-row {
  background: rgba(79, 119, 163, 0.4);
}

.el-table .success-row {
  background: transparent;
}
/*最外层透明*/
.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  background-color: transparent;
}
</style>
<style lang="less" scoped>
.diagrambox {
  width: 100%;
  height: 100%;
  background: url('../../assets/homeimg/bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  .echarbiongt {
    width: 100%;
    height: 100%;
  }
  &-topbox {
    width: 96%;
    height: 40px;
    margin: 100px 0 1% 2%;
    // margin-left: 2%;
    // margin-bottom: 1%;
    display: flex;
    align-items: center;
    /deep/.el-input__inner {
      background-color: #5376a1;
      border: none;
      color: #bfdafd;
      font-size: 12px;
      border-radius: 0px;
    }
    // //item选项的hover样式
    // /deep/ .el-select-dropdown__item.hover,
    // /deep/ .el-select-dropdown__item:hover {
    //   color: #409eff;
    // }
    // /deep/ .el-select-dropdown {
    //   background-color: #08164d;
    //   margin: 0px;
    //   border: none;
    //   border-radius: 0px;
    // }
    // /deep/ .el-popper .popper__arrow,
    // .el-popper .popper__arrow::after {
    //   display: none;
    // }
    // /deep/ .el-select-dropdown__item.hover,
    // /deep/ .el-select-dropdown__item:hover {
    //   color: #409eff;
    // }

    p {
      margin: 0 15px;
      color: #fff;
      font-size: 16px;
      font-family: SourceHanSansCN;
      font-weight: 400;
    }
    .el-button {
      margin-left: 15px;
      margin-right: 15px;
    }
    .el-input {
      width: 180px;
    }
  }
  &-center {
    width: 96%;
    height: 75%;
    background: url('../../assets/homeimg/k1.png') no-repeat;
    background-size: 100% 100%;
    margin-left: 2%;
    overflow: hidden;
    display: flex;
    .leftbox {
      width: 20%;
      height: 96%;
      margin: 1% 0 0 1%;
      position: relative;
      div {
        width: 96%;
        height: 17%;
        margin: 2% 0 0 2%;

        .maxbox {
          background: rgba(131, 180, 239, 0.1);
          width: 100%;
          height: 100%;
          overflow: hidden;
          margin-left: -0.5%;
          .title {
            font-size: 13px;
            font-family: SourceHanSansCN;
            font-weight: bold;
            color: #dddddd;
            margin-left: 5%;
            margin-top: 2%;
          }
          .detailsbox {
            width: 95%;
            height: 65%;
            display: flex;
            .imgbox {
              width: 40%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .textboxs {
              width: 49%;
              height: 100%;
              margin-top: -2%;

              p {
                font-size: 10px;
                font-family: SourceHanSansCN;
                font-weight: bold;
                color: #dddddd;
                margin-left: 5%;
                line-height: 70%;
                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      div.action {
        background-color: #000;
        background: rgba(131, 180, 239, 0.5);
        // background: url('../../assets/homeimg/2331.png') no-repeat;
        // background-size: 100% 100%;
      }

      .paging {
        position: absolute;
        bottom: 2%;
        left: -8%;
        width: 96%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        /deep/.el-input__inner {
          background: transparent;
          border: 1px solid #abdaff;
          color: #fff;
        } //input背景颜色
        /deep/.btn-prev {
          background: transparent;
          color: #fff;
        } //左箭头按钮背景
        /deep/.btn-next {
          background: transparent;
          color: #fff;
        } //右箭头按钮背景
        /deep/.number {
          background: transparent;
          color: #fff;
        } //未被选中的页码背景
        /deep/.active {
          background: #0090ff !important;
          color: #fff;
        } //被选中的页码背景
        /deep/.el-pagination.is-background .el-pager li {
          background: transparent;
          color: #fff;
        } //被折叠的页码背景
        /deep/.el-pagination__total {
          color: #fff;
        } //总条数字体颜色
        /deep/.el-pagination__jump {
          color: #fff;
        } //前往第几页数字体颜色
      }
    }
    .centerbox {
      width: 52%;
      height: 96%;
      margin: 1% 0 0 2%;
      overflow: hidden;
      .videobox {
        width: 100%;
        height: 65%;
        margin-top: 2%;
        img {
          width: 100%;
          height: 100%;
        }
        video {
          object-fit: contain;
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
        canvas {
          width: 70%;
          height: 110%;
        }
      }
      .videofooter {
        width: 100%;
        height: 26%;
        margin-top: 1%;
        // display: flex;
        // justify-content: space-around;
        // align-items: center;
        white-space: nowrap;
        display: -webkit-box;
        overflow-x: scroll;
        .modebox {
          width: 32%;
          height: 98%;
          margin-left: 1%;
          // img {
          //   width: 100%;
          //   height: 100%;
          // }
        }
      }
      /* 设置滚动条的样式 */
      ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background: transparent;
      }
      /* 滚动条滑块 */
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: transparent;
        //  background: rgba(230, 252, 255, 0.5);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }
    }
    .rightbox {
      width: 22%;
      height: 96%;
      overflow: hidden;
      margin: 1% 0 0 2%;
      .btnbox {
        width: 100%;
        height: 50px;
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;
        border-bottom: 1px solid rgba(98, 145, 200, 0.6);
        .el-button {
          width: 60px;
          height: 30px;
          background: rgba(66, 150, 239, 0.8);
          border: 1px solid #6da2da;
          border-radius: 4px;
        }
      }
      .center_box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow: hidden;
        .top_box {
          width: 100%;
          height: 80px;
          // border-top: 1px solid rgba(98, 145, 200, 0.6);
          // border-bottom: 1px solid rgba(98, 145, 200, 0.6);
          padding-top: 10px;
          overflow: hidden;
          p {
            font-size: 16px;
            font-family: SourceHanSansCN;
            font-weight: bold;
            color: #e6fcff;
            line-height: 6px;
            margin-left: 20px;
            // overflow: hidden;
            span {
              font-size: 14px;
              font-family: SourceHanSansCN;
              font-weight: bold;
              color: #86c1fe;
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
          .mintext {
            font-size: 12px;
            font-family: SourceHanSansCN;
            font-weight: bold;
            color: #bfdafd;
            // margin: 10px 0;
          }
          .mintexttwo {
            font-size: 12px;
            font-family: SourceHanSansCN;
            font-weight: bold;
            color: #86c1fe;
            line-height: 8px;
          }
        }

        .takebox {
          width: 100%;
          height: 80px;
          font-family: SourceHanSansCN;
          font-weight: bold;
          color: #e6fcff;
          overflow: hidden;
          background: rgba(131, 180, 239, 0.2);
          // opacity: 0.2;
          margin-top: 10px;
          .namebox {
            width: 100%;
            height: 100%;
            p {
              padding: 5px 0 0 20px;
              margin: 10px 0;
            }
          }
        }
        .imgviderbox {
          width: 100%;
          height: 60%;
          // background: #0090ff;
          overflow-y: auto;
          .imgbox {
            width: 100%;
            height: 200px;
            margin-top: 10px;
            img {
              width: 100%;
              height: 100%;
            }
            video {
              width: 100%;
              height: 100%;
            }
          }
        }
        /* 设置滚动条的样式 */
        ::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background: RGBA(56, 74, 97, 0.2);
        }
        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: RGBA(56, 74, 97, 0.6);
          -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
        }
        //  .takebox {
        //   width: 80%;
        //   height: 60px;
        //   font-size: 14px;
        //   font-family: SourceHanSansCN;
        //   font-weight: bold;
        //   color: #e6fcff;
        //   border-bottom: 1px solid rgba(98, 145, 200, 0.6);
        //   overflow: hidden;
        //   .namebox {
        //     display: flex;
        //     margin-top: 5px;
        //     img {
        //       width: 20px;
        //       height: 20px;
        //     }
        //     p {
        //       margin: 0 10px;
        //     }
        //     div {
        //       width: 50px;
        //       height: 20px;
        //       background: rgba(66, 150, 239, 0.4);
        //       border: 1px solid #6da2da;
        //       opacity: 0.8;
        //       border-radius: 2px;
        //       font-size: 12px;
        //       color: #bfdafd;
        //       text-align: center;
        //     }
        //   }
        //   .timebox {
        //     display: flex;
        //     margin: 5px 0 0 10%;
        //     overflow: hidden;
        //     color: #bfdafd;
        //     img {
        //       width: 14px;
        //       height: 14px;
        //       margin-top: 5px;
        //     }
        //     p {
        //       margin: 0 10px;
        //     }
        //   }
        // }
      }
    }
  }
  /*弹层动画（从上往下）*/
  .fadeIn {
    -webkit-animation: fadeInDown 0.3s;
    animation: fadeInDown 0.3s;
  }
  @keyframes fadeInDown {
    0% {
      -webkit-transform: translate3d(0, -20%, 0);
      -webkit-transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeInDown {
    0% {
      -webkit-transform: translate3d(0, -20%, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: none;
      opacity: 1;
    }
  }
}
</style>
