<template>
  <div class="details">
    <div class="details-top">
      <TitleBox :titlename="'分派'" />
      <div class="closebox" @click="cancel()">
        <p class="el-icon-close"></p>
        <p>关闭</p>
      </div>
    </div>
    <div class="details-center">
      <div class="selectbox">
        <p>预警类型:</p>
        <div class="selectdatabox">
          <el-select v-model="deviceAddobj.riskType" placeholder="请选择"
            :popper-append-to-body="false" clearable>
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="selectbox">
        <p>协同类型:</p>
        <div class="selectdatabox">
          <el-radio v-model="deviceAddobj.partnerType" label="0">协同执法</el-radio>
          <el-radio v-model="deviceAddobj.partnerType" label="1">协同查证</el-radio>
        </div>
      </div>
      <div class="selectbox">
        <p>负&ensp;责&ensp;人:</p>
        <div class="selectdatabox">
          <el-select @change="recursiveAlgorithm($event)" v-model="value" placeholder="请选择"
            :popper-append-to-body="false" clearable>
            <el-option v-for="item in userlist" :key="item.id" :label="item.realname" :value="{value:item.id,label:item.realname}">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="selectbox">
        <p>协调人员:</p>
        <div class="selectdatabox">

          <el-cascader v-model="value" :options="dutylist" :show-all-levels="false" :props="{
          expandTrigger: 'hover',
          label: 'name',
          children: 'sonList',
          value: 'id',
        }"></el-cascader>

        </div>
      </div> -->
      <div class="namebox">
        <p>备&emsp;注:</p>
        <div class="remarkbox">
          <el-input type="textarea" placeholder="请输入备注" :rows="4"
            v-model="deviceAddobj.riskDescribe"></el-input>
        </div>
      </div>

    </div>
    <div class="btnbox">
      <el-button size="mini" class="cancel" type="primary" @click="cancel()">取消</el-button>
      <el-button size="mini" class="affirm" type="primary" @click="addbtn()">确认</el-button>
    </div>
  </div>
</template>

<script>
import api from '../visualization/api'
import TitleBox from '../visualization/components/title/index.vue'
export default {
  props: ['detailsshow'],
  components: {
    TitleBox
  },
  data () {
    return {
      radio: 0,
      filterText: '',
      options: [
        {
          id: 0,
          name: '疑似船只'
        },
        {
          id: 1,
          name: '疑似车辆'
        },
        {
          id: 2,
          name: '疑似人员'
        }
      ],
      value: '',
      deviceAddobj: {
        riskType: '', // 预警类型
        partnerType: '', // 协同类型
        userId: '',
        userName: '',
        riskDescribe: '' // 描述信息
      },
      data: [{
        id: 1,
        label: '大湾区',
        children: [{
          id: 4,
          label: '赵三'
        }, {
          id: 5,
          label: '刘大'
        }, {
          id: 6,
          label: '王四'
        }, {
          id: 7,
          label: '刘二'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      remark: '',
      userlist: [],
      dutylist: []

    }
  },
  computed: {},
  watch: {
    // filterText (val) {
    //   this.$refs.tree.filter(val)
    // }
  },
  methods: {
    handleChange (value) {
    },
    cancel () {
      this.$emit('cancel')
    },
    addbtn () {
      this.$emit('addbtn', this.deviceAddobj)
      // console.log(this.deviceAddobj)
    },
    footerbtn (index) {
      this.footerAction = index
    },
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    recursiveAlgorithm (dataList) {
      // console.log(dataList, 'dataList')
      this.deviceAddobj.userId = dataList.value
      this.deviceAddobj.userName = dataList.label
    },
    // sonListAlgorithm (dataList) {
    //   dataList.forEach(item => {
    //     if (item.sonList && item.sonList.length > 0 && item.sonList !== null) {
    //       this.sonListAlgorithm(item.sonList)
    //     } else {
    //       delete item.sonList
    //     }
    //   })
    //   return dataList
    // },
    pegeuser () {
      api.UserTreePage().then(res => {
        // console.log(res, '[[[[')
        this.userlist = res.data
        // console.log(res, '//////')
      })
    }
    // pageduty () {
    //   api.dutyTreePage().then(res => {
    //     // console.log(res, 'xiezuo renyuan ')
    //     this.dutylist = this.sonListAlgorithm(res.data)
    //     // console.log(this.dutylist, 'dutylist')
    //   })
    // }
  },
  created () {
    this.pegeuser()
    // this.pageduty()
  }
}
</script>
<style lang="less" scoped>
.details {
  width: 30%;
  height: 70%;
  position: absolute;
  top: 15%;
  left: 35%;
  background: url('../../assets/homeimg/k2.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  border: 2px solid #59a5ff;
  z-index: 3;
  &-top {
    width: 92%;
    height: 60px;
    padding: 20px;
    margin-left: 4%;
    border-bottom: 1px solid #6ca0c7;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .closebox {
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }
  }
  /deep/.el-input__inner {
    background-color: transparent;
    border: none;
    color: #bfdafd;
    font-size: 12px;
    border-radius: 0px;
  }
  /deep/.el-textarea__inner {
    background-color: rgba(108, 160, 199, 0.5);
    color: #bfdafd;
    font-size: 12px;
    border: 1px solid #6ca0c7;
  }

  &-center {
    width: 92%;
    height: 70%;
    border-bottom: 1px solid #6ca0c7;
    margin-left: 4%;
    color: #ffffff;
    .selectbox {
      width: 100%;
      height: 15%;
      display: flex;
      margin-top: 5%;
      p {
        margin-left: 8%;
        margin-top: 3%;
      }
      .selectdatabox {
        // display: flex;
        margin-left: 5%;
        background: rgba(108, 160, 199, 0.5);
        border: 1px solid #6ca0c7;
        width: 60%;
        height: 100%;
        .el-select,
        .el-cascader {
          width: 90%;
          border: 1px solid #6ca0c7;
          margin: 5px 5%;
        }
        .el-radio {
          margin: 8% 0 0 8%;
          color: #abbbbd;
        }
      }
    }
    .namebox {
      width: 100%;
      height: 50%;
      display: flex;
      margin-top: 5%;
      p {
        margin-left: 10%;
        margin-top: 3%;
      }
      .treebox {
        // display: flex;
        margin-left: 5%;
        background: rgba(108, 160, 199, 0.5);
        border: 1px solid #6ca0c7;
        width: 60%;
        height: 100%;
        .el-input {
          width: 90%;
          border: 1px solid #6ca0c7;
          margin: 5px 5%;
        }
        &-top {
          width: 100%;
          height: 40px;
          border-bottom: 1px solid #6ca0c7;
          padding: 10px;
        }
      }
      .remarkbox {
        margin-left: 5%;
        // background: rgba(108, 160, 199, 0.5);
        // border: 1px solid #6ca0c7;
        width: 60%;
        height: 30%;
      }
    }
  }
  .btnbox {
    width: 100%;
    height: 14%;

    .el-button {
      margin-left: 5%;
      height: 30px;
      margin-top: 5%;
    }
    .cancel {
      border: 1px solid #6da2da;
      background: transparent;
      margin-left: 70%;
    }
  }
}
</style>
